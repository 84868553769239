/* reboot */
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

html,
body,
div,
ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
dfn,
del,
p,
blockquote,
fieldset,
input,
textarea,
select,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
button,
canvas,
video {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
address,
caption,
cite,
code,
th,
dfn,
del {
	font-weight: normal;
	font-style: normal;
}

ul,
ol {
	list-style: none;
}

fieldset,
img {
	border: none;
}

caption,
th {
	text-align: left;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

svg:not(:root) {
	overflow: hidden;
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
	cursor: pointer;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
input[type="search"],
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	border-radius: 0;
}

input,
button,
textarea {
	font-family: "Avenir-web", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: normal;
}

input[type="search"] {
	-webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type="hidden"],
[hidden],
.hidden {
	display: none;
}

textarea {
	overflow: auto;
	resize: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

a > img {
	vertical-align: bottom;
}

strong,
.strong,
b {
	font-weight: bold;
}

em,
.italic,
i {
	font-style: italic;
}

del,
.del {
	text-decoration: line-through;
}

:focus {
	outline: none;
}

img {
	max-width: 100%;
	width: auto;
	height: auto;
}

.map-canvas img {
	max-width: none;
}

.clear {
	clear: both;
	display: block;
	line-height: 0;
	height: 0;
	font-size: 0;
}

.clearfix::after, .wysiwyg::after, .wrap::after, .content-time-info::after {
	clear: both;
	content: "";
	display: table;
}

.alignleft {
	float: left !important;
}

.alignright {
	float: right !important;
}

.aligncenter {
	margin-left: auto !important;
	margin-right: auto !important;
}

.overflow-hidden {
	overflow: hidden;
	display: block;
}

.nowrap {
	white-space: nowrap;
}

.content-box {
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

html {
	height: 100%;
	background: #eaeef1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	font-family: "Avenir-web", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.572;
	height: 100%;
	color: #000000;
}

/* typography */
.svg {
	display: block;
}

.svg svg {
	vertical-align: top;
	display: block;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 100%;
	margin: 0;
}

.svg-stroke,
.svg-fill {
	-webkit-transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1), fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1), fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1), fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

a {
	color: #34c459;
	text-decoration: none;
	background-color: transparent;
	-webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

a:hover {
	color: #28a749;
}

a:focus {
	color: #28a749;
}

a.underline {
	text-decoration: underline;
}

.wysiwyg strong {
	font-weight: 900;
}

.wysiwyg h1 {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg h2 {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 500;
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg h3 {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg h4 {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg h5 {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg h6 {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg p {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg a {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.wysiwyg img {
	display: block;
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg img.alignleft {
	margin: 22px 22px 22px 0;
}

.wysiwyg img.alignright {
	margin: 22px 0 22px 22px;
}

.wysiwyg blockquote {
	margin-top: 22px;
	margin-bottom: 22px;
	padding-left: 22px;
	font-style: italic;
}

.wysiwyg blockquote p {
	margin-bottom: 0;
}

.wysiwyg hr {
	clear: both;
	margin-top: 22px;
	margin-bottom: 22px;
	border: 0;
	border-bottom: 1px solid #f2f4f4;
}

.wysiwyg hr.transparent {
	border-bottom-color: transparent;
}

.wysiwyg ul {
	overflow: hidden;
	margin-top: 22px;
	margin-bottom: 22px;
	list-style: inside disc;
}

.wysiwyg ul li {
	padding-left: 22px;
}

.wysiwyg ol {
	display: block;
	padding-left: 16px;
	margin-top: 22px;
	margin-bottom: 22px;
	list-style: outside decimal;
}

.wysiwyg ol li {
	padding-left: 20px;
}

.wysiwyg table {
	margin-top: 22px;
	margin-bottom: 22px;
}

.wysiwyg table th {
	padding: 11px 22px;
	background: #f2f4f4;
	border: 1px solid #f2f4f4;
}

.wysiwyg table td {
	padding: 11px 22px;
	border: 1px solid #f2f4f4;
}

@font-face {
	font-family: 'Avenir-web';
	src: url(84fccdefd06b8b6e780742e72b422265.eot);
	src: url(84fccdefd06b8b6e780742e72b422265.eot?#iefix) format("embedded-opentype"), url(e1b9c5c4fe824e1908ce07e6dc898722.woff2) format("woff2"), url(03d2adb4a9874102ea2cd24843779063.woff) format("woff"), url(0cf249161121aa4aeebdc927f1000094.ttf) format("truetype"), url(52fc9b9ad54e8ee1fefca153d94be556.svg#AvenirRoman) format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir-web';
	src: url(76c1557da4e5de32f1a731cb860bed5e.eot);
	src: url(76c1557da4e5de32f1a731cb860bed5e.eot?#iefix) format("embedded-opentype"), url(ee40630e2798f0fc1aa8947b97c28d96.woff2) format("woff2"), url(c3e02f4a9415ea4e2c563c012492efe0.woff) format("woff"), url(d6a8eb48ff86c41c521cae895289ad6a.ttf) format("truetype"), url(a889fd2fa505ab4bec915d2116fbcf25.svg#AvenirMedium) format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir-web';
	src: url(142ba0734911684deff9df40a205625e.eot);
	src: url(142ba0734911684deff9df40a205625e.eot?#iefix) format("embedded-opentype"), url(542c27b66186e4e072704459544a6a30.woff2) format("woff2"), url(424f601aef202a9f02b2b3fb89431b8d.woff) format("woff"), url(a0f1dec811d8203fcf1cc33b164a1a9f.ttf) format("truetype"), url(ebc9561ce5f74ae3d4b35a14f4c98429.svg#AvenirMediumOblique) format("svg");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir-web';
	src: url(4aabb50c432b0e0e9a913df3101ecf33.eot);
	src: url(4aabb50c432b0e0e9a913df3101ecf33.eot?#iefix) format("embedded-opentype"), url(3e59e32c55592efec36c22930f9edd6e.woff2) format("woff2"), url(bc6d3e5dc6cb9485e7971e246cdf0704.woff) format("woff"), url(5209a75a5db5dbbe48e267060422068b.ttf) format("truetype"), url(66c598b45cc2a00db2262e55ceffca80.svg#AvenirHeavy) format("svg");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir-web';
	src: url(87a8219bd5011f5d981b2091d7b80fb6.eot);
	src: url(87a8219bd5011f5d981b2091d7b80fb6.eot?#iefix) format("embedded-opentype"), url(3aad55e9ab0b353f936368cfd573bfea.woff2) format("woff2"), url(ef9197d98a7cd608ab820e70c102e20c.woff) format("woff"), url(e5a953936f619ae0bb401e2e085698b6.ttf) format("truetype"), url(365e52c8ef7779ff9d2b64ccbbcab9fc.svg#AvenirBlack) format("svg");
	font-weight: 900;
	font-style: normal;
}

/* grid */
.main {
	padding-bottom: 173px;
	min-height: 100%;
	overflow: hidden;
	background: url(20cbd480edd75dd476f3d16c6a763f28.jpg) 50% 100% no-repeat;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;
	background-size: cover;
}

.wrap {
	max-width: 1220px;
	margin: 0 auto;
	padding: 0 20px;
}

/* forms */
.error {
	color: #e32d2d !important;
	border-color: #e32d2d !important;
	
	border-width: 2px;
	border-style: solid;
}

.success {
	color: #85bb33 !important;
	border-color: #85bb33 !important;
	
	border-width: 2px;
	border-style: solid;
}

.aktiv {
	border-color: #85bb33 !important;
	
	border-width: 2px;
	border-style: solid;
}

.disabled {
	color: #727d86 !important;
	border-color: #727d86 !important;
	cursor: default !important;
}

.input-text-wrapper {
	margin-bottom: 27px;
}

input.input-text, textarea.textarea {
	float: left;
	width: 100%;
	height: 45px;
	padding: 11px 15px 13px;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
	background: #ffffff;
	border: 0;
	border-radius: 0;
	-webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

textarea.textarea {
	font-family: "Avenir-web", Helvetica, Arial, sans-serif;
	overflow: auto;
	height: auto;
}

select.select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	border-radius: 0;
}

select.select::-ms-expand {
	display: none;
}

select.select.selcust {
	float: left;
	width: 100%;
	height: 45px;
	padding: 11px 15px 13px;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
	background: #ffffff;
	border: 0;
	
	background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1.2em + 2px), calc(100% - 15px) calc(1.2em + 2px);
	background-size: 5px 5px, 5px 5px;
	background-repeat: no-repeat;
}

.checkbox,
.radio {
	display: block;
}

.checkbox input,
.radio input {
	display: none;
}

.checkbox input:checked + label:after,
.radio input:checked + label:after {
	opacity: 1;
}

.checkbox input:disabled + label,
.radio input:disabled + label {
	cursor: default;
}

.checkbox label,
.radio label {
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding: 12px 38px 12px 0;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
	text-transform: uppercase;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox label:before,
.radio label:before {
	content: "";
	position: absolute;
	right: 0;
	top: 13px;
	width: 18px;
	height: 18px;
	background: #ffffff;
	-webkit-transition: -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.checkbox label:after,
.radio label:after {
	opacity: 0;
	content: "";
	position: absolute;
	right: 2px;
	top: 18px;
	width: 14px;
	height: 9px;
	background: url(d6ed68597425d9a2fc28f271e528e9e7.svg) 0 0 no-repeat;
	-moz-background-size: 14px 9px;
	-o-background-size: 14px 9px;
	-webkit-background-size: 14px 9px;
	background-size: 14px 9px;
	-webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.checkbox label:focus:before,
.radio label:focus:before {
	-webkit-box-shadow: 0 0 0 3px rgba(40, 167, 73, 0.1);
	box-shadow: 0 0 0 3px rgba(40, 167, 73, 0.1);
}

a.button,
input.button,
button.button {
	display: inline-block;
	vertical-align: top;
	height: 65px;
	padding: 0 30px;
	font-size: 21px;
	line-height: 65px;
	text-transform: uppercase;
	color: #ffffff;
	text-align: center;
	white-space: nowrap;
	-webkit-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	background: #34c459;
	border: 0;
}

a.button:hover,
input.button:hover,
button.button:hover {
	background-color: #28a749;
}

a.button:hover .button-arrow,
input.button:hover .button-arrow,
button.button:hover .button-arrow {
	background-color: #239240;
}

a.button:focus,
input.button:focus,
button.button:focus {
	background-color: #28a749;
	-webkit-box-shadow: 0 0 0 3px rgba(40, 167, 73, 0.1);
	box-shadow: 0 0 0 3px rgba(40, 167, 73, 0.1);
}

a.button:focus .button-arrow,
input.button:focus .button-arrow,
button.button:focus .button-arrow {
	background-color: #239240;
}

a.button.button-small,
input.button.button-small,
button.button.button-small {
	height: 48px;
	padding: 0 15px;
	font-size: 14px;
	line-height: 48px;
}

a.button.button-red,
input.button.button-red,
button.button.button-red {
	background: #df4141;
}

a.button.button-red .button-arrow,
input.button.button-red .button-arrow,
button.button.button-red .button-arrow {
	background-color: #c83434;
}

a.button.button-red:hover,
input.button.button-red:hover,
button.button.button-red:hover {
	background-color: #c83434;
}

a.button.button-red:hover .button-arrow,
input.button.button-red:hover .button-arrow,
button.button.button-red:hover .button-arrow {
	background-color: #b42f2f;
}

a.button.button-red:focus,
input.button.button-red:focus,
button.button.button-red:focus {
	background-color: #c83434;
	-webkit-box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
	box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
}

a.button.button-red:focus .button-arrow,
input.button.button-red:focus .button-arrow,
button.button.button-red:focus .button-arrow {
	background-color: #b42f2f;
}

a.button.button-orange,
input.button.button-orange,
button.button.button-orange {
	background: #f3bf0c;
}

a.button.button-orange .button-arrow,
input.button.button-orange .button-arrow,
button.button.button-orange .button-arrow {
	background-color: #dca114;
}

a.button.button-orange:hover,
input.button.button-orange:hover,
button.button.button-orange:hover {
	background-color: #dca114;
}

a.button.button-orange:hover .button-arrow,
input.button.button-orange:hover .button-arrow,
button.button.button-orange:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-orange:focus,
input.button.button-orange:focus,
button.button.button-orange:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-orange:focus .button-arrow,
input.button.button-orange:focus .button-arrow,
button.button.button-orange:focus .button-arrow {
	background-color: #c59012;
}

input.button,
button.button {
	line-height: normal;
}

a.button {
	position: relative;
	padding-right: 100px;
}

a.button.button-small {
	padding-right: 67px;
}

a.button.button-small .button-arrow {
	width: 52px;
}

a.button.button-small .button-arrow .svg {
	margin-left: -8px;
	margin-top: -5px;
	width: 17px;
	height: 12px;
}

.button-arrow {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 70px;
	background: #28a749;
	-webkit-transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.button-arrow .svg {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -11px;
	margin-top: -7px;
	width: 23px;
	height: 16px;
}

.modal-open {
	overflow: hidden;
}

.modal-backdrop {
	background: #000000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9998;
}

.modal-backdrop,
.modal-backdrop.fade.in {
	opacity: 0.6;
}

.modal {
	width: 802px;
	max-width: 100%;
	max-height: 100%;
	overflow: auto;
	position: absolute;
	display: none;
	z-index: 9999;
	background: #ffffff;
	-webkit-box-shadow: 0 2px 68px rgba(0, 0, 0, 0.08);
	box-shadow: 0 2px 68px rgba(0, 0, 0, 0.08);
}

.modal.modal-center {
	position: fixed;
}

.modal-session {
	text-align: center;
	padding: 75px 50px 96px;
}

.modal-session-img {
	margin: 0 auto 17px;
	width: 85px;
}

.modal-session-title {
	font-weight: 500;
	font-size: 44px;
	line-height: 1.2;
	margin-bottom: 7px;
}

.modal-session-info {
	font-size: 16px;
	font-weight: 500;
	font-style: italic;
	color: #505051;
	margin-bottom: 52px;
}

/* header */
.header-global {
	padding: 20px 0 26px;
	background: rgba(255, 255, 255, 0.5);
}

.logo {
	float: left;
	padding-top: 5px;
}

.logo a {
	display: block;
	max-width: 220px;
}

.logo a img {
	display: block;
	width: 100%;
}

.logo-team {
	float: right;
}

.logo-team a,
.logo-team img {
	display: block;
}

/* footer */
.panel {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 5050;
	background: rgba(36, 42, 56, 0.8);
}

.panel-list-slider {
	/* width: 100%; */
	overflow-x: hidden;
}
.panel-list {
	/* Problem 2
	margin-left: -10px;
	margin-right: -10px;
	*/
	margin-left: 0;
	margin-right: 0;

	padding-top: 17px;
	padding-bottom: 17px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.panel-list > li {
	padding: 0 10px;
	/* flex: 1; */
}

.panel-thumb {
	display: block;
	width: 180px;
	height: 139px;
	border: 3px solid #313949;
	background-color: #ffffff;
	padding: 10px;
	text-align: center;
	position: relative;
}

.panel-thumb:hover, .panel-thumb:focus {
	border-color: #FFF;
}

.panel-thumb-opacity {
	opacity: 0.5;
}

.panel-thumb-image {
	display: block;
	margin: 0 auto;
}

.panel-thumb-image img {
	display: block;
	margin: 0 auto;
}

.panel-thumb-title {
	padding-left: 5px;
	padding-top: 5px;
	display: block;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 1.2;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 15px;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}

.panel-thumb-title-small {
	font-size: 10px;
	font-weight: 500;
}

.panel-thumb-image-line {
	padding-top: 8px;
	display: block;
}

.panel-thumb-image-line span {
	display: inline-block;
	vertical-align: bottom;
}

.panel-thumb-image-line-sep {
	width: 6px;
}

.panel-thumb-image-line-num {
	font-size: 23px;
	line-height: 1;
	color: #212425;
	font-weight: 900;
}

.panel-thumb-image-line-wrap {
	width: 26px;
}

.panel-thumb-image-line-wrap img {
	display: block;
}

.panel-list-navigation {
	float: right;
	padding-top: 55px;
	padding-bottom: 55px;
}

.button-go-step-4 {
	min-width: 280px;
}

.button-go-step-10, .button-go-step-8, .button-go-step-9, .button-go-step-11, .button-go-step-12 {
	min-width: 400px;
}

.panel-thumb-date {
	/* padding-top: 11px; */
	padding-top: 0 !important;
	display: block;
	line-height: 1.2;
	text-transform: uppercase;
	
	max-height: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
}

.panel-thumb-date span {
	display: block;
}

.panel-thumb-date-dow {
	/* font-size: 11px; */
	font-size: 17px;
}

.panel-thumb-date-num {
	font-weight: 900;
	/* font-size: 34px; */
	font-size: 42px;
}

.panel-thumb-date-mth {
	/* font-size: 9px; */
	font-size: 13px;
}

.panel-thumb-time {
	color: #010101;
	display: block;
}

.panel-thumb-time-num {
	padding-top: 32px;
	display: block;
	font-size: 40px;
	line-height: 1;
	font-weight: 500;
	margin-bottom: 4px;
}

.panel-thumb-time-title {
	display: block;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 1.2;
	font-weight: 500;
}

.panel-list-navigation-price {
	color: #212425;
	font-size: 24px;
	text-align: right;
	display: inline-block;
	background: #ffffff;
	margin-right: -4px;
	height: 65px;
	line-height: 65px;
	text-transform: uppercase;
	padding: 0 20px;
}

.panel-list-navigation-price-title {
	text-align: left;
	font-size: 18px;
	display: inline-block;
	min-width: 290px;
}

.panel-extra-push {
	height: 60px;
}

/* content */
.spanning {
	clear: both;
}

.content-title {
	padding: 43px 0 33px;
}

.content-title h1 {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 500;
	/* text-transform: uppercase; */
	text-align: center;
	color: #063a72;
}

.content-iframe {
	max-width: 900px;
	margin: 0 auto 50px;
	background: rgba(255, 255, 255, 0.5);
}

.content-iframe img {
	display: block;
}

.content {
	padding: 37px 48px 54px;
	max-width: 900px;
	margin: 0 auto 50px;
	background: rgba(255, 255, 255, 0.5);
}

.content-full {
	padding: 24px 24px 15px;
	margin: 0 auto 20px;
	background: rgba(255, 255, 255, 0.5);
}

.content-full .panel-list {
	margin-left: -5px;
	margin-right: -5px;
}

.content-full .panel-list > li {
	padding-left: 5px;
	padding-right: 5px;
	width: 16.66667%;
}

.content-full .panel-thumb {
	width: 100%;
	/* border: 0; */
	border-color: transparent;
	background: rgba(255, 255, 255, 0.5);
}

.content-full span.panel-thumb:hover,
.content-full span.panel-thumb:focus {
	border-color: #ffffff;
}

.content-full .panel-thumb-title {
	font-weight: 500;
}

.content-full .panel-thumb-date {
	padding-top: 28px;
}

.content-full .panel-thumb-image-line {
	padding-top: 3px;
}

.content-full-title {
	font-size: 16px;
	font-weight: 900;
	color: #212425;
	line-height: 1.2;
	text-align: center;
	text-transform: uppercase;
}

.content-panel-list-price {
	padding-top: 4px;
	font-size: 21px;
	line-height: 1.2;
	color: #212425;
	text-align: right;
	text-transform: uppercase;
}

.content-price-title {
	display: inline-block;
	min-width: 135px;
	text-align: left;
}

.signing-info {
	margin-bottom: 16px;
}

.signing-info h2 {
	margin-top: 13px;
}

.signing-info-note {
	margin-bottom: -22px;
}

.signing-form {
	max-width: 650px;
}

.signing-form table {
	width: 100%;
}

.signing-form table td {
	padding: 0;
	vertical-align: top;
}

.signing-form table td label {
	display: block;
	padding: 12px 0;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
	text-transform: uppercase;
}

.signing-form table td:first-child {
	width: 213px;
	padding-right: 20px;
}

.signing-form-button {
	padding: 17px 0 8px;
}

.signing-form-button a.button {
	min-width: 300px;
}

.change-ticket {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}

.change-ticket-row {
	display: table-row;
}

.change-ticket-date,
.change-ticket-route,
.change-ticket-number {
	display: table-cell;
	vertical-align: top;
	padding: 21px 25px 18px 0;
	background: #ffffff;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
}

.change-ticket-date {
	width: 220px;
	padding-left: 40px;
}

.change-ticket-route {
	font-weight: 900;
}

.change-ticket-number {
	text-align: right;
}

.change-options {
	padding-top: 20px;
	margin: 0 -33px;
}

.change-options-inline {
	padding-top: 0;
	padding-bottom: 20px;
	text-align: center;
}

.change-options-inline .change-options-button {
	display: inline-block;
	padding-left: 14px;
	float: none;
	padding-right: 14px;
	width: auto;
	min-width: 250px;
}

.change-options-button {
	float: left;
	width: 33.33333333%;
	padding: 20px 33px 0;
}

.change-options-button a.button {
	width: 100%;
}

.personal-info {
	padding-top: 5px;
	max-width: 607px;
	margin: 0 auto;
}

.personal-info table {
	width: 100%;
}

.personal-info table td {
	padding: 0;
	vertical-align: top;
}

.personal-info table td.col1 {
	width: 170px;
	padding-right: 20px;
}

.personal-info table td.col1 label {
	display: block;
	padding: 12px 0;
	font-size: 15px;
	line-height: 1.4;
	font-weight: 500;
	text-transform: uppercase;
}

.personal-info table td.col2 {
	width: 165px;
	padding-right: 30px;
}

.personal-info .checkbox label {
	min-width: 372px;
}

.payment {
	padding-top: 23px;
}

.content-note {
	margin-bottom: 55px;
	padding: 12px 20px 22px 38px;
	font-weight: 500;
	font-style: italic;
	color: #2d6242;
	background: #ace7bb;
}

.content-note-step-13 {
	margin-bottom: 15px;
	margin-top: 40px;
	padding-bottom: 14px;
}

.payment-later .checkbox label {
	display: block;
	padding-right: 0;
	padding-left: 38px;
	padding-bottom: 7px;
	font-size: 14px;
	line-height: 1.43;
	font-style: italic;
	text-transform: none;
}

.payment-later .checkbox label:before {
	right: auto;
	left: 0;
}

.payment-later .checkbox label:after {
	right: auto;
	left: 2px;
}

.payment-list {
	padding-top: 14px;
	position: relative;
}

.payment-list:before {
	content: "";
	position: absolute;
	top: 0;
	left: 95px;
	right: 95px;
	height: 1px;
	background: #e8e8e8;
}

.payment-item {
	display: table;
	width: 100%;
	margin-bottom: 14px;
}

.payment-item-row {
	display: table-row;
}

.payment-item-title,
.payment-item-sum,
.payment-item-number,
.payment-item-system {
	display: table-cell;
	vertical-align: middle;
	padding: 5px 10px 5px 0;
	background: #ffffff;
}

.payment-item-title {
	padding-left: 16px;
}

.payment-item-number {
	white-space: nowrap;
}

.payment-item-system {
	width: 196px;
	padding-right: 8px;
}

.payment-item-system img {
	float: right;
}

.order {
	padding-top: 11px;
	max-width: 614px;
	margin: 0 auto 52px;
}

.order .payment-later {
	margin-bottom: 23px;
}

.order-contact {
	margin-bottom: 3px;
}

.order-info {
	background: #ffffff;
}

.order-info table {
	width: 100%;
}

.order-info table th {
	padding: 13px 18px 0 0;
	vertical-align: top;
	font-weight: 500;
}

.order-info table th:first-child {
	padding-left: 18px;
}

.order-info table th:last-child {
	text-align: right;
}

.order-info table td {
	padding: 8px 18px 0 0;
	vertical-align: top;
	color: #666666;
	font-weight: 500;
}

.order-info table td:first-child {
	padding-left: 18px;
}

.order-info table td.col1 {
	padding-left: 54px;
}

.order-info table td:last-child {
	text-align: right;
}

.order-info table tbody tr:last-child td {
	padding-bottom: 20px;
}

.order-info table tbody tr.first-child td {
	padding-top: 14px;
}

.order-info table tr.order-info-route td {
	padding-bottom: 11px;
	text-align: left;
	border-bottom: 1px solid #e8e8e8;
}

.order-info table tfoot td {
	padding-top: 17px;
	padding-bottom: 19px;
	font-weight: 900;
	color: #000000;
	border-top: 1px solid #e8e8e8;
}

.thumbnails-medium {
	margin-left: -8px;
	margin-right: -8px;
}

.thumbnails-medium > li {

	max-width: 33.3333%;
	padding: 15px 8px 1px;
}

.thumbnails-medium .thumbnail-image {
	margin-bottom: 5px;
	height: 140px;
	position: relative;
}

.thumbnails-medium .thumbnail-title {
	padding-bottom: 11px;
}

.thumbnail {
	display: block;
	background: rgba(255, 255, 255, 0.5);
	border: 5px solid transparent;
	text-align: center;
	color: #212425;
	padding: 10px;
}

a.thumbnail:hover, a.thumbnail:focus {
	color: #212425;
	border-color: #ffffff;
}

.thumbnail-image {
	display: block;
	margin-bottom: 13px;
}

.thumbnail-image img {
	display: block;
	margin: 0 auto;
}
.thumbnails-medium .thumbnail-image .Vertical-Measure-Left,
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Right,
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Top,
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Bottom {
	font-size: 17px;
	position: absolute;
	text-transform: uppercase;
}
.thumbnails-medium .thumbnail-image img {
	max-height: 100%;  
	max-width: 100%; 
	width: auto;
	height: auto;

	position: absolute;  
	top: 0;  
	bottom: 0;  
	left: 0;  
	right: 0;  
	margin: auto;
}
.thumbnails-medium .thumbnail-image .Vertical-Measure-Left {
	-webkit-transform: matrix(0.006,-1.000,1.000,0.006,-115,55);
	-moz-transform: matrix(0.006,-1.000,1.000,0.006,-115px,55px);
	-ms-transform: matrix(0.006,-1.000,1.000,0.006,-115,55);
	-o-transform: matrix(0.006,-1.000,1.000,0.006,-115,55);
	transform: matrix(0.006,-1.000,1.000,0.006,-115,55);
}
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Right {
	-webkit-transform: matrix(1,0,0,1,60,55);
	-moz-transform: matrix(1,0,0,1,60px,55px);
	-ms-transform: matrix(1,0,0,1,60,55);
	-o-transform: matrix(1,0,0,1,60,55);
	transform: matrix(1,0,0,1,60,55);
}
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Top {
	top: 0;
	left: 0;
	right: 0;
	-webkit-transform: matrix(1,0,0,1,-4,3);
	-moz-transform: matrix(1,0,0,1,-4px,3px);
	-ms-transform: matrix(1,0,0,1,-4,3);
	-o-transform: matrix(1,0,0,1,-4,3);
	transform: matrix(1,0,0,1,-4,3);
}
.thumbnails-medium .thumbnail-image .Horizontal-Measure-Bottom {
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transform: matrix(1,0,0,1,-2,-10);
	-moz-transform: matrix(1,0,0,1,-2px,-10px);
	-ms-transform: matrix(1,0,0,1,-2,-10);
	-o-transform: matrix(1,0,0,1,-2,-10);
	transform: matrix(1,0,0,1,-2,-10);
}

.thumbnail-title {
	display: block;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 1.2;
}

.info-block {
	position: absolute;
	left: 8px;
	top: 15px;
	right: 8px;
	height: 0;
}

.info-block:hover .info-block-drop {
	display: block;
}

.info-block-icon {
	position: absolute;
	width: 30px;
	height: 30px;
	background: #1c4b7e;
	color: #ffffff;
	border-radius: 50%;
	left: 15px;
	top: 12px;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	font-weight: bold;
	font-style: italic;
	font-family: Times, Georgia, serif;
}

.info-block-drop {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 16px;
	display: none;
}

.info-block-drop-content {
	display: block;
	background: #1c4b7e;
	color: #ffffff;
	font-size: 14px;
	line-height: 19px;
	padding: 17px 14px 33px;
	position: relative;
}

.info-block-drop-content:before {
	content: "";
	position: absolute;
	bottom: -26px;
	left: 18px;
	width: 0;
	height: 0;
	border: 13px solid transparent;
	border-top-color: #1c4b7e;
}

.info-block-drop-content p {
	margin: 10px 0;
}

.info-block-drop-content strong {
	text-transform: uppercase;
	font-weight: 16px;
}

.thumbnails-global-title {
	background: #85bb33;
	color: #ffffff;
	text-align: center;
	font-weight: 16px;
	text-transform: uppercase;
	line-height: 1.2;
	font-weight: 900;
	padding: 18px 10px 17px;
	letter-spacing: 1px;
}

.thumbnails-global-title-orange {
	background-color: #e1a31d;
}

.thumbnails-global-title-blue {
	background-color: #1d68a6;
}

.thumbnails-global-title {
	margin-top: 10px;
}

.thumbnail-image-count {
	position: relative;
	display: block;
	margin: 0 auto 6px;
	max-width: 329px;
	padding: 27px 0;
}

.plus,
.minus {
	width: 50px;
	height: 50px;
	background: #b5becf;
	border-radius: 50%;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -20px;
}

.plus:before, .plus:after,
.minus:before,
.minus:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 4px;
	background: #ffffff;
	margin-left: -10px;
	margin-top: -2px;
}

.plus:after,
.minus:after {
	height: 20px;
	width: 4px;
	margin-left: -2px;
	margin-top: -10px;
}

.plus:hover,
.minus:hover {
	background-color: #85bb33;
}

.plus-orange:hover,
.minus-orange:hover {
	background-color: #e1a31d;
}

.plus-blue:hover,
.minus-blue:hover {
	background-color: #1d68a6;
}

.minus {
	right: auto;
	left: 0;
}

.minus:after {
	display: none;
}

.count {
	font-size: 55px;
	line-height: 1;
	font-weight: 800;
	vertical-align: bottom;
	margin-right: 5px;
	display: inline-block;
}

.count-img {
	display: inline-block;
	vertical-align: bottom;
	margin-bottom: 4px;
}

.count-img img {
	display: block;
}

.check-form {
	max-width: 415px;
	margin: 0 auto;
	padding-bottom: 10px;
	padding-top: 14px;
}

.check-form input.input-text, .check-form textarea.textarea {
	height: 50px;
	text-align: center;
	font-size: 18px;
}

.check-form-note {
	margin-bottom: 23px;
}

.caledar-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -11px;
	margin-right: -11px;
	padding-bottom: 20px;
}

.caledar-column {
	padding: 21px 11px 0px;
	width: 14.28571%;
}

.calendar-item {
	display: block;
	background: rgba(255, 255, 255, 0.5);
	border: 5px solid transparent;
	text-align: center;
	color: #000000;
	text-transform: uppercase;
	padding: 34px 5px 20px;
}

.calendar-item:hover, .calendar-item:focus {
	color: #000000;
	border-color: #ffffff;
}

.calendar-item-disabled {
	cursor: default;
	opacity: 0.45;
}

.calendar-item-disabled:hover, .calendar-item-disabled:focus {
	color: #000000;
	border-color: transparent;
}

.calendar-item-today {
	background-color: #85bb33;
	color: #ffffff;
}

.calendar-item-today:hover, .calendar-item-today:focus {
	color: #ffffff;
	border-color: #85bb33;
}

.calendar-item-dof {
	display: block;
	font-size: 16px;
	line-height: 1.2;
	font-weight: 500;
	margin-bottom: 4px;
}

.calendar-item-num {
	display: block;
	font-size: 51px;
	line-height: 1;
	font-weight: 900;
	margin-bottom: 2px;
}

.calendar-item-mnt {
	display: block;
	font-size: 14px;
	line-height: 1.2;
	font-weight: 500;
}

.font-weight-medium {
	font-weight: 500;
}

.content-title {
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
}

.content-title h1 strong {
	font-weight: 800;
}

.caledar-prev,
.caledar-next {
	right: 0;
	top: 50%;
	margin-top: 2px;
	position: absolute;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	-webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 0.5;
}

.caledar-prev:hover,
.caledar-next:hover {
	opacity: 1;
}

.caledar-control-nav {
	width: 20px;
	padding: 5px 0 5px 9px;
	display: block;
}

.caledar-control-nav img {
	display: block;
	width: 100%;
	height: auto;
}

.caledar-prev {
	right: auto;
	left: 0;
}

.caledar-prev .caledar-control-nav {
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.content-time-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -10px;
	margin-right: -10px;
	padding-bottom: 20px;
}

.content-time-column {
	padding: 20px 10px 0px;
	width: 20%;
}

.content-time-item {
	display: block;
	background: rgba(255, 255, 255, 0.5);
	border: 5px solid transparent;
	text-align: center;
	color: #000000;
}

.content-time-item:hover, .content-time-item:focus {
	color: #000000;
	border-color: #ffffff;
}

.content-time-item-now .content-time-num,
.content-time-item-now .content-time-title {
	color: #85bb33;
}

.content-time-item-disabled {
	cursor: default;
	opacity: 0.5;
}

.content-time-item-disabled:hover, .content-time-item-disabled:focus {
	color: #000000;
	border-color: transparent;
}

.content-time-num {
	display: block;
	font-size: 53px;
	line-height: 1;
	font-weight: 500;
	margin-bottom: 3px;
	padding-top: 30px;
}

.content-time-title {
	display: block;
	font-size: 14px;
	line-height: 1.2;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 10px;
}

.content-time-note {
	display: block;
	font-size: 11px;
	line-height: 1.2;
	font-weight: 900;
	color: #85bb33;
	text-transform: uppercase;
	min-height: 15px;
	min-width: 1px;
	margin-bottom: 8px;
}

.content-time-note-red {
	color: #e32d2d;
}

.content-time-note-orange {
	color: #da9a10;
}

.content-time-info {
	background: #ffffff;
	margin: 0 -5px -5px;
}

.content-time-image {
	float: left;
	width: 43%;
	padding: 5px 0;
}

.content-time-image img {
	display: block;
}

.content-time-meta {
	float: left;
	width: 57%;
	padding-right: 14px;
	text-align: right;
	line-height: 1.2;
	text-transform: uppercase;
	font-weight: 900;
	padding-top: 22px;
	padding-bottom: 20px;
}

.content-time-meta-title {
	display: block;
	font-size: 11px;
	margin-bottom: 8px;
}

.content-time-price {
	display: block;
	font-size: 14px;
}

.slick-slide {
	padding: 0 10px;
}

.showHide {
	display: none;
}

.tilOversigt {
    right: 30px;
    font-size: 18px !important;
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 10px !important;
	margin-top: -11px !important;
    position: absolute !important;
}

/* home */
/* responsive */
@media (max-width: 1023px) {
	.panel-list > li {
		/* width: 33%; */
	}
	.panel-thumb {
		/* width: 100%; */
		height: 105px;
	}
	.panel-list-navigation {
		padding-top: 37px;
		padding-bottom: 37px;
	}
	.button-go-step-4 {
		min-width: 240px;
	}
	.button-go-step-10, .button-go-step-8, .button-go-step-9, .button-go-step-11, .button-go-step-12 {
		min-width: 320px;
	}
	.panel-thumb-image {
		max-width: 100px;
	}
	.panel-thumb-title {
		font-weight: normal;
		font-weight: 12px;
	}
	.panel-thumb-image-line {
		padding-top: 2px;
	}
	.panel-thumb-image-line-num {
		font-size: 16px;
	}
	.panel-thumb-image-line-wrap {
		width: 22px;
	}
	.change-ticket-date {
		padding-left: 25px;
	}
	.change-options {
		margin: 0 -15px;
	}
	.change-options-button {
		padding-left: 15px;
		padding-right: 15px;
	}
	.calendar-item {
		padding-top: 18px;
		padding-bottom: 15px;
	}
	.calendar-item-dof {
		font-size: 13px;
	}
	.calendar-item-num {
		font-size: 40px;
	}
	.calendar-item-mnt {
		font-size: 11px;
	}
	.caledar-list {
		margin-left: -8px;
		margin-right: -8px;
	}
	.caledar-column {
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 16px;
	}
	.panel-thumb-date {
		padding-top: 0;
	}
	.content-full .panel-thumb-image-line {
		padding-top: 0px;
		margin-top: -2px;
	}
	.content-full .panel-thumb-date {
		padding-top: 20px;
	}
	.content-full .panel-thumb-time-num {
		padding-top: 18px;
		font-size: 32px;
	}
	.panel-thumb-date-dow {
		font-size: 11px;
	}
	.panel-thumb-date-num {
		font-size: 24px;
	}
	.panel-thumb-date-mth {
		font-size: 9px;
	}
	.content-time-column {
		width: 25%;
	}
	.content-time-num {
		font-size: 44px;
	}
	.content-time-note {
		font-weight: 500;
		font-size: 10px;
	}
	.panel-thumb-image-line-sep {
		width: 1px;
	}
	.panel-list-navigation-price-title {
		min-width: 0;
	}
}

@media (max-width: 767px) {
	.tilOversigt {
		right: 0;
		left: 0;
		top: 0;
		font-size: 18px !important;
		height: 40px !important;
		line-height: 40px !important;
		padding: 0 10px !important;
		margin-top: 0 !important;
	}
	.modal-session {
		padding: 60px 20px;
	}
	.modal-session-img {
		width: 66px;
	}
	.modal-session-title {
		font-size: 34px;
	}
	.modal-session-info {
		margin-bottom: 40px;
	}
	.logo a {
		max-width: 180px;
	}
	.content {
		padding-left: 20px;
		padding-right: 20px;
	}
	.signing-form {
		max-width: none;
	}
	.signing-form table {
		display: block;
	}
	.signing-form table tbody,
	.signing-form table tr {
		display: block;
	}
	.signing-form table td {
		display: block;
	}
	.signing-form table td:first-child {
		width: auto;
		padding-right: 0;
	}
	.signing-form-button a.button {
		min-width: 0;
		width: 100%;
	}
	.thumbnail-title {
		font-size: 18px;
	}
	.panel {
		width: 100%;
		bottom: -129px;
	}
	.panel.medNav {
		bottom: -228px;
	}
	.panel.medBtn {
		bottom: -90px;
	}
	.slide-up {
		bottom: 0px !important;
	}
	.slide-down	{
		bottom: -129px !important;
	}
	.panel .panel-list-navigation,
	.panel .panel-list {
		padding-top: 34px;
		overflow-x: scroll;
	}
	.panel.medNav .panel-list {
		padding-top: 17px;
	}
	.panel .panel-list {
		padding-bottom: 0;
	}
	.panel-list > li {
		padding: 0 10px 17px;
	}
	.showHide {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap;
		justify-content: center;
		position: relative;
		top: -15px;
	}
	.showHide .btnShowHide {
		display: block;
		height: 30px;
		line-height: 30px;
		position: absolute;
		padding-right: 0;
		padding-left: 0;
		width: 110px;

		/*Problem 3*/
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	
	.main {
		padding-bottom: 100px;
	}
	.panel-list-navigation {
		padding-top: 17px;
		padding-bottom: 17px;
		float: none;
		text-align: center;
	}
	.change-ticket {
		display: block;
	}
	.change-ticket-row {
		display: block;
	}
	.change-ticket-date,
	.change-ticket-route,
	.change-ticket-number {
		display: block;
		padding: 8px 20px;
	}
	.change-ticket-date {
		width: auto;
		padding-top: 20px;
	}
	.change-ticket-number {
		text-align: left;
		padding-bottom: 20px;
	}
	.change-options {
		margin: 0;
	}
	.change-options-button {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
	.button-go-step-10, .button-go-step-8, .button-go-step-9, .button-go-step-11, .button-go-step-12 {
		min-width: 0;
	}
	.check-form input.input-text, .check-form textarea.textarea {
		height: 45px;
		font-size: 15px;
	}
	.personal-info {
		padding-top: 0;
		max-width: none;
	}
	.personal-info table {
		display: block;
	}
	.personal-info table tbody,
	.personal-info table tr {
		display: block;
	}
	.personal-info table td {
		display: block;
	}
	.personal-info table td.col1 {
		width: auto;
		padding-right: 0;
	}
	.personal-info table td.col1 label {
		padding-top: 0;
	}
	.personal-info table td.col2 {
		width: auto;
		padding-right: 0;
	}
	.personal-info .checkbox label {
		display: block;
		min-width: 0;
	}
	.caledar-column {
		width: 25%;
	}
	.payment {
		padding-top: 0;
	}
	.content-note {
		padding-left: 20px;
		margin-bottom: 30px;
	}
	.payment-list:before {
		display: none;
	}
	.payment-item,
	.payment-item-row {
		display: block;
	}
	.payment-item-title,
	.payment-item-sum,
	.payment-item-number,
	.payment-item-system {
		display: block;
		vertical-align: top;
		padding: 4px 20px;
	}
	.payment-item-title {
		padding-top: 16px;
	}
	.payment-item-system {
		width: auto;
		padding-bottom: 16px;
	}
	.payment-item-system img {
		float: none;
		display: block;
	}
	.content-time-column {
		width: 33.3333%;
	}
	.order {
		max-width: none;
		padding-top: 0;
		margin-bottom: 0;
	}
	.order-info table {
		display: block;
	}
	.order-info table thead {
		display: none;
	}
	.order-info table tr,
	.order-info table td {
		display: block;
	}
	.order-info table td {
		padding-right: 20px;
		padding-left: 20px;
	}
	.order-info table td:first-child, .order-info table td.col1 {
		padding-left: 20px;
	}
	.order-info table td:last-child {
		text-align: left;
		border-bottom: 1px solid #e8e8e8;
		padding-bottom: 14px;
	}
	.order-info table tbody {
		display: block;
	}
	.order-info table tbody td:before {
		content: attr(data-title);
		margin-right: 6px;
		font-weight: 800;
	}
	.order-info table tbody tr:last-child td {
		padding-bottom: 0;
	}
	.order-info table tbody tr:last-child td:last-child {
		padding-bottom: 14px;
	}
	.order-info table tbody tr.first-child td {
		padding-top: 8px;
	}
	.order-info table tr.order-info-route td {
		padding-top: 14px;
		padding-bottom: 14px;
	}
	.order-info table tr.order-info-route td:before {
		display: none;
	}
	.order-info table tfoot {
		display: block;
	}
	.order-info table tfoot td {
		padding-top: 17px;
		padding-bottom: 0;
		border-top: 0;
	}
	.order-info table tfoot td:last-child {
		padding-top: 8px;
		border-bottom: 0;
		padding-bottom: 19px;
	}
	.content-full .panel-list {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.content-full .panel-list > li {
		width: 33.33333%;
	}
	.panel-list-navigation-price {
		color: #ffffff;
		background-color: transparent;
		display: block;
		text-align: center;
		line-height: 1.2;
		height: auto;
		padding-bottom: 15px;
		font-size: 18px;
	}
}

@media (max-width: 479px) {
	.thumbnail-title {
		font-size: 18px;
	}
	.caledar-column {
		width: 33.33333%;
	}
	a.button,
	input.button,
	button.button {
		font-size: 16px;
	}
	a.button {
		padding-left: 20px;
		padding-right: 90px;
	}
	.content-time-column {
		width: 50%;
	}
	.content-full .panel-list > li {
		width: 50%;
	}
	.content-panel-list-price {
		text-align: center;
	}
	.content-price-title {
		min-width: inherit;
	}
	.content-price-title:after {
		content: ": ";
	}
	.change-options-inline .change-options-button {
		display: block;
		padding-left: 0;
		padding-right: 0;
	}
}

/* retina */



/* spinner*/
.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

    .sk-fading-circle .sk-circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

        .sk-fading-circle .sk-circle:before {
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            background-color: #333;
            border-radius: 100%;
            -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
        }

    .sk-fading-circle .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg);
    }

    .sk-fading-circle .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg);
    }

    .sk-fading-circle .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .sk-fading-circle .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg);
    }

    .sk-fading-circle .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg);
    }

    .sk-fading-circle .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .sk-fading-circle .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg);
    }

    .sk-fading-circle .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg);
    }

    .sk-fading-circle .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    .sk-fading-circle .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg);
    }

    .sk-fading-circle .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg);
    }

    .sk-fading-circle .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .sk-fading-circle .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .sk-fading-circle .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .sk-fading-circle .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    .sk-fading-circle .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .sk-fading-circle .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

    .sk-fading-circle .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .sk-fading-circle .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }

    .sk-fading-circle .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }

    .sk-fading-circle .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
    }

    .sk-fading-circle .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

