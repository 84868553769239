a.delayed-enable {
    pointer-events: none;
}


.thumbnails-global-title-default {
    background-color: #34c459;
    color:black !important;
}
.thumbnails-global-title-island {
    background-color: #990000;
    color:white !important;
}
.thumbnails-global-title-clip {
    background-color: #f3bf0c;
    color:black !important;
}
.thumbnails-global-title-commercial {
    background-color: #1d68a6;
}
.thumbnails-global-title-truck {
    background-color: #59B1B2;
    color:black !important;
}
.thumbnails-global-title-special {
    background-color: #c68c53;
    color:black !important;
}
.thumbnails-global-title-student {
    background-color: #f3bf0c;
    color:black !important;
}
.thumbnails-global-title-nekseloe {
    background-color: #c68c53;
    color:black !important;
}
.thumbnails-global-title-driverless {
    background-color: #990000;
    color:white !important;
}
.thumbnails-global-title-elevator {
    background-color: #990000;
    color:black !important;
}
.thumbnails-global-title-commuter {
    background-color: #f3bf0c;
    color:black !important;
}
.thumbnails-global-title-walking {
    background-color: #f3bf0c;
    color:black !important;
}
.thumbnails-global-title-disabled {
    background-color: #f3bf0c;
    color:black !important;
}
.thumbnails-global-title-ambulance {
    background-color: #f3bf0c;
    color:black !important;
}

/* default arrow button */
a.button.button-default,
input.button.button-default,
button.button.button-default {
    background: #34c459;
    color: black !important;
}

a.button.button-default .button-arrow,
input.button.button-default .button-arrow,
button.button.button-default .button-arrow {
	background-color: #28a749;
}

a.button.button-default:hover,
input.button.button-default:hover,
button.button.button-default:hover {
	background-color: #28a749;
}

a.button.button-default:hover .button-arrow,
input.button.button-default:hover .button-arrow,
button.button.button-default:hover .button-arrow {
	background-color: #239240;
}

a.button.button-default:focus,
input.button.button-default:focus,
button.button.button-default:focus {
	background-color: #28a749;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-default:focus .button-arrow,
input.button.button-default:focus .button-arrow,
button.button.button-default:focus .button-arrow {
	background-color: #239240;
}

/* island arrow button */
a.button.button-island,
input.button.button-island,
button.button.button-island {
	background: #990000;
}

a.button.button-island .button-arrow,
input.button.button-island .button-arrow,
button.button.button-island .button-arrow {
	background-color: #7d1111;
}

a.button.button-island:hover,
input.button.button-island:hover,
button.button.button-island:hover {
	background-color: #7d1111;
}

a.button.button-island:hover .button-arrow,
input.button.button-island:hover .button-arrow,
button.button.button-island:hover .button-arrow {
	background-color: #b42f2f;
}

a.button.button-island:focus,
input.button.button-island:focus,
button.button.button-island:focus {
	background-color: #7d1111;
	-webkit-box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
	box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
}

a.button.button-island:focus .button-arrow,
input.button.button-island:focus .button-arrow,
button.button.button-island:focus .button-arrow {
	background-color: #b42f2f;
}

/* clip arrow button */
a.button.button-clip,
input.button.button-clip,
button.button.button-clip {
    background: #f3bf0c;
    color: black !important;
}

a.button.button-clip .button-arrow,
input.button.button-clip .button-arrow,
button.button.button-clip .button-arrow {
	background-color: #dca114;
}

a.button.button-clip:hover,
input.button.button-clip:hover,
button.button.button-clip:hover {
	background-color: #dca114;
}

a.button.button-clip:hover .button-arrow,
input.button.button-clip:hover .button-arrow,
button.button.button-clip:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-clip:focus,
input.button.button-clip:focus,
button.button.button-clip:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-clip:focus .button-arrow,
input.button.button-clip:focus .button-arrow,
button.button.button-clip:focus .button-arrow {
	background-color: #c59012;
}

/* commercial arrow button */
a.button.button-commercial,
input.button.button-commercial,
button.button.button-commercial {
    background: #1d68a6;
}

a.button.button-commercial .button-arrow,
input.button.button-commercial .button-arrow,
button.button.button-commercial .button-arrow {
	background-color: #175282;
}

a.button.button-commercial:hover,
input.button.button-commercial:hover,
button.button.button-commercial:hover {
	background-color: #175282;
}

a.button.button-commercial:hover .button-arrow,
input.button.button-commercial:hover .button-arrow,
button.button.button-commercial:hover .button-arrow {
	background-color: #13446c;
}

a.button.button-commercial:focus,
input.button.button-commercial:focus,
button.button.button-commercial:focus {
	background-color: #175282;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}
/*
a.button.button-clip:focus .button-arrow,
input.button.button-clip:focus .button-arrow,
button.button.button-clip:focus .button-arrow {
	background-color: #13446c;
}
*/

/* truck arrow button */
a.button.button-truck,
input.button.button-truck,
button.button.button-truck {
    background: #59B1B2;
    color: black !important;
}

a.button.button-truck .button-arrow,
input.button.button-truck .button-arrow,
button.button.button-truck .button-arrow {
	background-color: #4D9A9B;
}

a.button.button-truck:hover,
input.button.button-truck:hover,
button.button.button-truck:hover {
	background-color: #4D9A9B;
}

a.button.button-truck:hover .button-arrow,
input.button.button-truck:hover .button-arrow,
button.button.button-truck:hover .button-arrow {
	background-color: #478D8E;
}

a.button.button-truck:focus,
input.button.button-truck:focus,
button.button.button-truck:focus {
	background-color: #4D9A9B;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

/* a.button.button-clip:focus .button-arrow,
input.button.button-clip:focus .button-arrow,
button.button.button-clip:focus .button-arrow {
	background-color: #996633;
} */


/* special arrow button */
a.button.button-special,
input.button.button-special,
button.button.button-special {
    background: #c68c53;
    color: black !important;
}

a.button.button-special .button-arrow,
input.button.button-special .button-arrow,
button.button.button-special .button-arrow {
	background-color: #ac7339;
}

a.button.button-special:hover,
input.button.button-special:hover,
button.button.button-special:hover {
	background-color: #ac7339;
}

a.button.button-special:hover .button-arrow,
input.button.button-special:hover .button-arrow,
button.button.button-special:hover .button-arrow {
	background-color: #996633;
}

a.button.button-special:focus,
input.button.button-special:focus,
button.button.button-special:focus {
	background-color: #ac7339;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-clip:focus .button-arrow,
input.button.button-clip:focus .button-arrow,
button.button.button-clip:focus .button-arrow {
	background-color: #996633;
}



/* clip arrow button */
a.button.button-student,
input.button.button-student,
button.button.button-student {
	background: #f3bf0c;
}

a.button.button-student .button-arrow,
input.button.button-student .button-arrow,
button.button.button-student .button-arrow {
	background-color: #dca114;
}

a.button.button-student:hover,
input.button.button-student:hover,
button.button.button-student:hover {
	background-color: #dca114;
}

a.button.button-student:hover .button-arrow,
input.button.button-student:hover .button-arrow,
button.button.button-student:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-student:focus,
input.button.button-student:focus,
button.button.button-student:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-student:focus .button-arrow,
input.button.button-student:focus .button-arrow,
button.button.button-student:focus .button-arrow {
	background-color: #c59012;
}


/* employee arrow button */
a.button.button-employee,
input.button.button-employee,
button.button.button-employee {
	background: #990000;
}

a.button.button-employee .button-arrow,
input.button.button-employee .button-arrow,
button.button.button-employee .button-arrow {
	background-color: #7d1111;
}

a.button.button-employee:hover,
input.button.button-employee:hover,
button.button.button-employee:hover {
	background-color: #7d1111;
}

a.button.button-employee:hover .button-arrow,
input.button.button-employee:hover .button-arrow,
button.button.button-employee:hover .button-arrow {
	background-color: #b42f2f;
}

a.button.button-employee:focus,
input.button.button-employee:focus,
button.button.button-employee:focus {
	background-color: #7d1111;
	-webkit-box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
	box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
}

a.button.button-employee:focus .button-arrow,
input.button.button-employee:focus .button-arrow,
button.button.button-employee:focus .button-arrow {
	background-color: #b42f2f;
}


/* nekseloe arrow button */
a.button.button-nekseloe,
input.button.button-nekseloe,
button.button.button-nekseloe {
	background: #c68c53;
}

a.button.button-nekseloe .button-arrow,
input.button.button-nekseloe .button-arrow,
button.button.button-nekseloe .button-arrow {
	background-color: #ac7339;
}

a.button.button-nekseloe:hover,
input.button.button-nekseloe:hover,
button.button.button-nekseloe:hover {
	background-color: #ac7339;
}

a.button.button-nekseloe:hover .button-arrow,
input.button.button-nekseloe:hover .button-arrow,
button.button.button-nekseloe:hover .button-arrow {
	background-color: #996633;
}

a.button.button-nekseloe:focus,
input.button.button-nekseloe:focus,
button.button.button-nekseloe:focus {
	background-color: #ac7339;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}


/* driverless arrow button */
a.button.button-driverless,
input.button.button-driverless,
button.button.button-driverless {
	background: #990000;
}

a.button.button-driverless .button-arrow,
input.button.button-driverless .button-arrow,
button.button.button-driverless .button-arrow {
	background-color: #7d1111;
}

a.button.button-driverless:hover,
input.button.button-driverless:hover,
button.button.button-driverless:hover {
	background-color: #7d1111;
}

a.button.button-driverless:hover .button-arrow,
input.button.button-driverless:hover .button-arrow,
button.button.button-driverless:hover .button-arrow {
	background-color: #b42f2f;
}

a.button.button-driverless:focus,
input.button.button-driverless:focus,
button.button.button-driverless:focus {
	background-color: #7d1111;
	-webkit-box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
	box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
}

/* elevator arrow button */
a.button.button-elevator,
input.button.button-elevator,
button.button.button-elevator {
	background: #990000;
}

a.button.button-elevator .button-arrow,
input.button.button-elevator .button-arrow,
button.button.button-elevator .button-arrow {
	background-color: #7d1111;
}

a.button.button-elevator:hover,
input.button.button-elevator:hover,
button.button.button-elevator:hover {
	background-color: #7d1111;
}

a.button.button-elevator:hover .button-arrow,
input.button.button-elevator:hover .button-arrow,
button.button.button-elevator:hover .button-arrow {
	background-color: #b42f2f;
}

a.button.button-elevator:focus,
input.button.button-elevator:focus,
button.button.button-elevator:focus {
	background-color: #7d1111;
	-webkit-box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
	box-shadow: 0 0 0 3px rgba(200, 52, 52, 0.1);
}


/* Pendler arrow button */
a.button.button-commuter,
input.button.button-commuter,
button.button.button-commuter {
    background: #f3bf0c;
    color: black !important;
}

a.button.button-commuter .button-arrow,
input.button.button-commuter .button-arrow,
button.button.button-commuter .button-arrow {
	background-color: #dca114;
}

a.button.button-commuter:hover,
input.button.button-commuter:hover,
button.button.button-commuter:hover {
	background-color: #dca114;
}

a.button.button-commuter:hover .button-arrow,
input.button.button-commuter:hover .button-arrow,
button.button.button-commuter:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-commuter:focus,
input.button.button-commuter:focus,
button.button.button-commuter:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-commuter:focus .button-arrow,
input.button.button-commuter:focus .button-arrow,
button.button.button-commuter:focus .button-arrow {
	background-color: #c59012;
}


/* Walking arrow button */
a.button.button-walking,
input.button.button-walking,
button.button.button-walking {
    background: #f3bf0c;
    color: black !important;
}

a.button.button-walking .button-arrow,
input.button.button-walking .button-arrow,
button.button.button-walking .button-arrow {
	background-color: #dca114;
}

a.button.button-walking:hover,
input.button.button-walking:hover,
button.button.button-walking:hover {
	background-color: #dca114;
}

a.button.button-walking:hover .button-arrow,
input.button.button-walking:hover .button-arrow,
button.button.button-walking:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-walking:focus,
input.button.button-walking:focus,
button.button.button-walking:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-walking:focus .button-arrow,
input.button.button-walking:focus .button-arrow,
button.button.button-walking:focus .button-arrow {
	background-color: #c59012;
}


/* Disabled arrow button */
a.button.button-disabled,
input.button.button-disabled,
button.button.button-disabled {
    background: #f3bf0c;
    color: black !important;
}

a.button.button-disabled .button-arrow,
input.button.button-disabled .button-arrow,
button.button.button-disabled .button-arrow {
	background-color: #dca114;
}

a.button.button-disabled:hover,
input.button.button-disabled:hover,
button.button.button-disabled:hover {
	background-color: #dca114;
}

a.button.button-disabled:hover .button-arrow,
input.button.button-disabled:hover .button-arrow,
button.button.button-disabled:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-disabled:focus,
input.button.button-disabled:focus,
button.button.button-disabled:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-disabled:focus .button-arrow,
input.button.button-disabled:focus .button-arrow,
button.button.button-disabled:focus .button-arrow {
	background-color: #c59012;
}

/* ambulance arrow button */
/* Walking arrow button */
a.button.button-ambulance,
input.button.button-ambulance,
button.button.button-ambulance {
    background: #f3bf0c;
    color: black !important;
}

a.button.button-ambulance .button-arrow,
input.button.button-ambulance .button-arrow,
button.button.button-ambulance .button-arrow {
	background-color: #dca114;
}

a.button.button-ambulance:hover,
input.button.button-ambulance:hover,
button.button.button-ambulance:hover {
	background-color: #dca114;
}

a.button.button-ambulance:hover .button-arrow,
input.button.button-ambulance:hover .button-arrow,
button.button.button-ambulance:hover .button-arrow {
	background-color: #c59012;
}

a.button.button-ambulance:focus,
input.button.button-ambulance:focus,
button.button.button-ambulance:focus {
	background-color: #dca114;
	-webkit-box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
	box-shadow: 0 0 0 3px rgba(220, 161, 20, 0.1);
}

a.button.button-ambulance:focus .button-arrow,
input.button.button-ambulance:focus .button-arrow,
button.button.button-ambulance:focus .button-arrow {
	background-color: #c59012;
}


.logo{
    max-width: 50%;
}
.logo a{
    max-width: 250px;
}

.logo a img{
    max-height: 38px;
    width: auto;
}

.vendor-friendly-name{
    font-size: 30px;
    font-weight: 500;
    color: #063a72;
    float: left;
    margin-left: 25px;
    margin-top: 5px;
}

.logo-team{
    margin-top: -3px;
    opacity: 0.4;
    margin-left: 12px;
    height: 30px;
}

.logo-team img{

    height: 30px;
    background-color: white;
    border-radius: 3px;
}

.flag-current{
    opacity: 0.7;
    float: right;
    margin-left: 12px;
    margin-top: 9px;
}

.flag-current img {
	display: block;
    height: 30px;
}

.flag-select{
    opacity: 0.7;
    float: right;
    margin-top: 9px;
    margin-left: 10px;
}

.flag-select img{
    height: 44px;
}

.menu {
	float: right;
    margin-left: 10px;
}

.menu a,
.menu img {
	display: block;
}
.menu-header {
    float: right;
    margin-top: 1px;
}
.menu-header-link-text {
    font-size: 20px;
    font-weight: 500;
    color: #1d8839;
}
.signin{
    padding-top: 8px;
}
.login{
    opacity: 0.6;
}

/* Team Booking*/
a {
    color: black;
}

a:hover {
    cursor: pointer;
}
.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}
:focus{
    outline: solid darkgrey !important;
}
a:focus{
    outline: solid darkgrey !important;
}
a.thumbnail:focus {
    outline: solid darkgrey !important;
}
.calendar-item:focus{
    outline: solid darkgrey !important;  
}

/* [type=checkbox].tab-check {
    visibility: visible !important;
    display: inline-block !important;
} */

#personal-terms{
    visibility: visible !important;
    /* opacity: 0 !important; */
}

.company-info{
    padding:20px;
    color: white;
}
.company-info-field{
    white-space: nowrap;
    padding: 5px;
}

.main{
    padding-bottom: 415px;
    animation: fadeIn 0.5s ease-in;

    /* background-image: none !important; */
}
.spanning{
    animation: fadeIn 0.5s ease-in;
}
.header-global{
    padding: 15px 0 10px;
}
.content-title{
    padding: 25px 20px 15px;
    color: #13446c;
}
.content-sub-title{
    padding-top: 10px;
    padding-bottom: 5px;
}
.thumbnails{
    padding-bottom: 5px;
}
.thumbnail{
    margin-bottom: 0px;
}

.thumbnail-image{
    height: 80px;
}

.thumbnail-image-only img{
    padding-bottom: 10px;
}
.thumbnails-global-title {
    padding: 13px 10px 12px;
}

.thumbnails-medium .thumbnail-image{
    height: 110px;
    padding-bottom: 0px;
    margin-bottom: -10px;
}

.thumbnails-medium .thumbnail-title{
    padding: 0px;
    font-weight: 600;
}
.thumbnail-title{
    text-transform: none;
    font-size: 16px;
    color: #13446c;
}
.thumbnail-title-navigation{
    font-size: 24px;
    padding-bottom: 8px;
}

.thumbnail-title:first-letter{
    text-transform: capitalize;
}

.Horizontal-Measure-Top{
    text-transform: none;    
    font-size: 18px;
}

.Vertical-Measure-Left {
    position: absolute;
    -webkit-transform: matrix(0.006,-1.000,1.000,0.006,-140,55);
	-moz-transform: matrix(0.006,-1.000,1.000,0.006,-140px,55px);
	-ms-transform: matrix(0.006,-1.000,1.000,0.006,-140,55);
	-o-transform: matrix(0.006,-1.000,1.000,0.006,-140,55);
    transform: matrix(0.006,-1.000,1.000,0.006,-140,55);
    padding-left: 45px;
    text-transform: none;
    font-size: 18px;
}

a.au-target.thumbnail.active {
    border-color: #337ab7;
}

.plus1, .minus1 {
    width: 50px;
    height: 50px;
    background: #b5becf;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 70%;
    margin-top: -20px;
}

.plus10, .minus10 {
    width: 50px;
    height: 50px;
    background: #b5becf;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 30%;
    margin-top: -20px;
}

.plus10:before, .plus10:after,
.minus10:before,
.minus10:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 6px;
	background: #ffffff;
	margin-left: -15px;
	margin-top: -3px;
}

.plus10:after{
	height: 30px;
	width: 6px;
	margin-left: -3px;
	margin-top: -15px;
}

.contact-email-label{
    margin: 7px 0px 20px 17px;
    font-size: 18px;
}

.contact-zip-label{
    font: 15px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 11px;
}

.contact-city-label{
    font: 15px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 11px;
}
.contact-coupon-subscription-info{
    padding: 0px 10px;
}

.contact-subscription-link{
    font: 17px;
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: uppercase;
    background: #34c459;
    padding: 15px;
    text-align: center;
    margin-top: 20px;;
    
}
.news-letter-label{
    text-transform: none;
}

@media all and (-ms-high-contrast:none)
{
.Vertical-Measure-Left {transform: matrix(0.006, -1, 1, 0.006, -30, 55)} /* IE10 */
*::-ms-backdrop, .Vertical-Measure-Left { transform: matrix(0.006, -1, 1, 0.006, -30, 55)} /* IE11 */
}

.thumbnails-global-title-info {
    padding-top: 10px;
    text-transform: none !important;
}
.thumbnail:hover {
    border: 5px solid #34c459 !important;
}
.thumbnail-passenger:hover {
    border: 5px solid transparent !important;
}
.meal-icon{
    background-color: #e8e8e8;
    padding: 0px 5px 5px 5px;
}
.meal-no-possible-increase{
    color: red;
}
.change-options.clearfix{
    padding-top: 0px;
    margin-top: -10px;
}
.change-option-title{
    padding-bottom: 10px;
}
.content{
    padding: 10px 20px 0px;
    margin: 0 auto 15px
}
.cookie-content{
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 20px 10px;
}
.message-info {
    background-color:  lightsteelblue;
}
.message-error {
    background-color: pink;
    font-size: larger;
}

.message-warning {
    background-color: #f0ad4e;
    font-size: larger;
}

.message-success {
    background-color: #5cb85c;
}
.wait-list-info{
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.caledar-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	display: -webkit-flex; /*IOS line wrap*/
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; /*IOS line wrap*/
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -11px;
	margin-right: -11px;
	padding-bottom: 20px;
}

.content-time-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
    display: flex;
	display: -webkit-flex; /*IOS line wrap*/
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
    flex-wrap: wrap;
	-webkit-flex-wrap: wrap; /*IOS line wrap*/
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -10px;
	margin-right: -10px;
	padding-bottom: 20px;
}
.content-time-title-header{
    font-size: 16px;
    font-weight: 800;
}
.content-title-departure-date{
    padding-left: 15px;
    padding-right: 15px; 
}
.content-wait-list{
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding-top: 10px;
    padding-bottom: 10px;
}
.departure-item-click-to-add-wait-list{
    color: #063a72;
    font-weight: 700;
}
.departure-item-your-reservation-included-info{
    color: green;
    font-weight: 700;
}

.calendar-item{
    transition: border .2s ease-in-out;
    text-transform: capitalize;
}
.calendar-item-active {
    border: 5px solid #337ab7;
}
.calendar-item:hover:enabled {
    border: 5px solid #34c459 !important;
    cursor: pointer !important;
    color: #000000;
}
.calendar-item-today {
    color: #1d8839;
    background-color: rgba(255, 255, 255, 0.5);
}
.calendar-item-noplan {
    color: red;
}
.calendar-item-note {
    color: #337ab7;
    font-weight: 600;
}

.caledar-prev{
    display: inline-flex;
    display: -webkit-inline-flex;
}
.caledar-next{
    display: inline-flex;
    display: -webkit-inline-flex;
}
.caledar-prev-next-date {
    font-size: 18px;
    font-weight: 900;
    text-transform: capitalize;
    color: #063a72;
}

.panel-list-summary{
    justify-content: center;
}

.content-time-item-active {
    border: 5px solid #337ab7;
}
.content-time-item:hover:enabled {
    border: 5px solid #34c459;
}
.disabled {
    border-color: #ddd !important;
}
    
.content-time-title{
    padding-top: 10px;
    margin-bottom: 0px;
    text-transform: none;
}

.departure-region-warning{
    color: orangered;
}

.content-time-info-svendborg {
    background-color: #B9C7E3 !important;
}

.content-time-info-faaborg {
    background-color: #F0D1E0 !important;
}

.content-time-info-fynshav {
    background-color: #FFEB99 !important;
}

.content-time-info-fem-kra {
    background-color: #B9C7E3 !important;
}

.content-time-info-ask-ban {
    background-color: #F0D1E0 !important;
}

.content-time-info-ask-kra {
    background-color: #FFEB99 !important;
}
.content-time-num-header{
    font-size: small;    
    margin-top: 0px;
    margin-bottom: 0px;
}
.content-time-num{
    padding-top: 5px;
    margin-bottom: 0px;
}
.content-time-num-arrives{
    font-size: small;    
    margin-top: -5px;
    margin-bottom: 3px;
}
.content-time-note{
    text-transform: none;
}
.content-time-info{
    margin: 0px;
}
.content-time-meta {
    padding-bottom: 0px;
}
.no-hover {
    pointer-events: none;
}

input.input-text {
    border: 1px solid rgba(163, 194, 194, .5);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */

}

input.input-text.error {
    border: 1px solid red;
}
select.select.selcust{
    border: 1px solid rgba(163, 194, 194, .5);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}


/*  Afprøves */
#personal-phone::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    opacity: 0.4;
}
#personal-phone:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    opacity: 0.4;
}
#personal-phone::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    opacity: 0.4;
}
#personal-phone:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.4;
}
.coupon-code-valid-for-tickets-wrapper{
    margin: 0px 0px 3px 3px;
    /* display: inline-block; */
}
.btn-coupon-code-valid-for-tickets{
    line-height: 17px !important;
    font-size: large !important; 
    height: 44px !important;
    width: 99%;
    margin: 5 0 0 5 !important;
    padding: 13px !important;
    text-transform: none !important;
}

.btn-coupon-code-valid-for-tickets.active {
    border: 3px solid #337ab7;
    border-color: #337ab7;
}

.content-title-guest-confirm {
    padding: 10px 0 10px;
}

.content-title-guest-confirm h1 {
    text-transform: none !important;
}
.check-form-note-guest-confirm{
    text-align: center;
}

.personal-info {
    max-width: 730px !important;
}
.personal-info table td.col1 {
    width: 250px !important;
}

.personal-info a {
    color: black;
}

.personal-info a:hover {
    cursor: pointer;
}

.checkbox label:before{
    border: 1px solid rgba(163, 194, 194, .7);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */ 
}

.button-go-step-8 {
    color: black !important;
}

.button-go-step-9 {
    padding-left: 0px !important;
    padding-right: 30px !important;
    min-width: 300px;
    color: black !important;
}
.button-go-step-10 {
    color: black !important;
}
.button-go-step-11{
    color: black !important;
}

a.button.button-orange{
    color: black !important;
}

a.button.button-green {
    color: black !important;
}

.order{
    padding-bottom: 15px;
}

.order-contact{
    margin-bottom: 10px;
    font-size: larger;
}

.order-info-route td {
    font-weight: 600 !important;
}
.order-info table tbody tr:last-child td {
    padding-bottom: 10px;
}

.content-note-step-13{
    padding-top: 20px;
    font-weight: 700;
    font-size: medium;
}
.content-note{
    background: #34c459;
}
.panel{
    margin-bottom: 0px;
    background: rgba(36, 42, 56, 0.6);
}

.panel-cookie{
    height: 80%;
    background: rgba(36, 42, 56, 0.9);
}

.panel-thumb {
    border: 4px solid transparent;
    transition: border .2s ease-in-out;
    font-size: 11px;
}
.panel-thumb-image img{
    padding-bottom: 7px;
    max-height: 70px;
    margin-bottom: 0px;
}
.panel-thumb-date {
    text-transform: capitalize;
}   

.panel-thumb-date-num {
    font-size: 35px;
}    
.panel-thumb-date-dow{
    font-size: 14px;
}
.panel-thumb-date-mth {
    font-size: 14px;
}   
.panel-thumb:hover {
    border: 4px solid #34c459;
    cursor: pointer;
}
a.panel-thumb.active {
    border: 4px solid #337ab7 !important;
}

.panel-list-navigation{
    overflow-x: hidden !important;
}
.panel-list-navigation-low{
    padding-top: 20px;
    padding-bottom: 20px;
}
.panel-list-slider {
    min-height: 50px;
}
.panel-thumb-bottom{
    width: 139px;
    height: 105px;
    animation: fadeIn 1s ease-in;
}
.panel-thumb-icon{
    width: 88px;
}
.panel-list-bottom{
    padding-bottom: 14px !important;
    padding-top: 10px !important;
}
.panel-thumb-image-bottom{
    height: 77px;
}
.panel-thumb-time-title{
    text-transform: capitalize;
    font-size: 14px;
}
.panel-thumb-time-num {
    padding-top: 10px;
}
.panel-thumb-time-num-bottom {
    font-size: 30px;
} 
.panel-thumb-image-line-wrap{
    width: 20px;
}

.showHide {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    display: -webkit-flex; /*IOS line wrap*/
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    top: -15px;
    display:  normal;
    z-index: 999;
    min-height: 20px;
    text-align: center;
}

.showHide .btnShowHide {
    height: 30px;
    line-height: 30px;
    width: 200px;
    font-size: 15px;
    color: black;
}

.tilOversigt{
    color: black!important;
}

.panel-thumb-temp{
    opacity: 0.6;
    animation: fadeInDownPanelThumbBottom 0.5s ease-in;
}
.panel-thumb-temp-image img{
    height: 60px;
}
.panel-thumb-temp-title{
    margin-top: -30px;
    font-size: 15px;
}

.my-menu-image{
    margin-top: 10px;
    margin-bottom: 0px;
}

.my-bookings-barcode{
    font-size: xx-large;
}

.my-bookings-status{
    font-size: larger;
    font-weight: 800;
}

.my-bookings-status-arrived{
    color: darkblue;
}

.my-bookings-status-closed{    
    color: red;
}

.my-bookings-status-payed{
    color: green;
}

.my-bookings-status-reserved {
    color: #a4692e;
}

.my-bookings-hr{
    margin: 3px;
    border-color: #337ab7;
    opacity: 0.3;
}
.my-booking-bookings-port{
    font-size: 16px;
    font-weight: 700;
    color: #13446c;
}
.my-booking-bookings-rese-note{
    color: red;

}
.thumbnail-coupon{
    padding-bottom: 0px !important;

}
.coupon-code-name{
    font-size: 16px;
    font-weight: 700;
    color: #13446c;
}
.coupon-code-code::first-letter{
    text-transform: none;
}

.coupon-details-coupon-name-input{
    border: 1px solid rgba(163, 194, 194, .5);
    padding: 10px;
}
.coupon-code-rest-amount{
    font-size: 16px;
    font-weight: 700;
    color: green;
}
.coupon-code-rest-amount-kredit{
    font-size: 16px;
    font-weight: 700;
    color: red;
}
.coupon-code-deposit-url{
    font-size: 16px;
    font-weight: 700;
    color: #13446c;
    padding-top: 0px !important;
}
.coupon-code-subscription-sign-up-url{
    color: #13446c;
}
.subscription-expiry-valid{
    color: green;
}

.change{
    padding-bottom: 15px;
}
.change-ticket {
    margin-bottom: 1px;
}
.content-coupon-entries{
    max-width: 1300px !important;
    margin-top: 30px;;
}
.coupon-entry{
    display: table-cell;
    vertical-align: top;
    padding: 10px 15px 8px 15px;
    background: #ffffff;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 500;
}
.coupon-entry-header{
    text-transform: uppercase;
}
.coupon-entry-date-time{
    width: 140px;
}
.coupon-entry-route{
    width: 210px;
}
.coupon-entry-booking{
    width: 80px;
}
.coupon-entry-qty{
    width: 30px;
    padding-left: 0px;
    padding-right: 0px;
}
.coupon-entry-ticket{
    min-width: 150px;
    padding-left: 0px;
}

.coupon-entry-amount{
    text-align: right;
}

.content-full-title{
    color: #13446c;
}

.content-full-title-disabled{
    color: red;
}

.contact-coupon-button-info{
    color: #34c459;
    font-size: 16px;
}

.contact-terms-lnk{
    color: #13446c;
}

.contact-terms-lnk:hover{
    text-decoration: underline;
}


.calendar-item-timetable{
    padding: 5px 5px 5px !important;
}
.calendar-item-num-timetable{
    font-size: 25px !important;
}
.cont-box-timetable{
    width: 40% !important;
    font-size: 16px;
}
.timetable-title{
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding-top: 15px;
}
.timetable-departs{
    background-color: rgba(255, 255, 255, 0.5);
    padding: 7px 10px 10px 10px;
}
.timetable-depart{
    float: left;
    width: 40px;
    text-align: right;
    font-weight: 800;
}
.timetable-depart-arrival-devider{
    float: left;
    width: 10px;
    text-align: center;
}
.timetable-arrival{
    float: left;
    width: 35px;
    font-size: 12px;
    padding-top: 4px;
}
.timetable-note{
    float: left;
    width: 250x;
    padding-left: 5px;
}
.timetable-book-url{
    float: right;
}
.timetable-tab{
    float: left;
    width: 40px;
    text-align: center;
}

.timetable-available-label{
    padding-left: 1px;
    font-size: 16px;
    line-height: 2;
}
.timetable-available-dangerous-goods-img{
    vertical-align: text-bottom;
    max-height: 22px;
    padding-left: 10px;
}
.timetable-available-cars-img{
    vertical-align: text-bottom;
    max-height: 20px;
    padding-left: 10px;
    white-space: nowrap;
    display: inline-block;
}
.timetable-available-pax-img{
    vertical-align: text-bottom;
    max-height: 20px;
    padding-left: 10px;
    white-space: nowrap;
    display: inline-block;
}
.timetable-available-qty{
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    display: inline-block;
}

.button-timetable-book{
    display: inline-block;
    vertical-align: top;
    height: 29px !important;
    padding: 9px !important;
    font-size: 12px !important;
    line-height: 12px !important;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    position: relative;

}

.crossing-time-exceeded{
    opacity: 0.5;
    color: #c72121;  
}
.crossing-blocked{
    color: #c72121;
}
.crossing-fully-booked{
    color: #c72121;
}
.crossing-almost-fully-booked{
    color:  #f3740c;
}
.crossing-comment{
    color: #337ab7;
}
.crossing-normal{
    color: #0e8335;
}

.normal{
    color: #0e8335;
}
.warning{
    color:  #f3740c;
}
.closed{
    color: #990000;
}

.wysiwyg h3 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 10px;
    color: #063a72;
}

@keyframes fadeInDownPanelThumbBottom {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (min-width: 767px) {
    .order-info-price {
        text-align: right;
    }    
    .guest-zip-input{
        padding-right: 20px;
    }
    .contact-zip-label{
        display: none;
    }    
    .contact-city-label{
        display: none;
    }
}

@media (min-width: 1023px) {
    .panel-thumb-buttom-passengers{
        padding: 0px;
    }
}

@media (max-width: 1235px) {
    .panel-list-slider {
        overflow-x: scroll;
    }
}

@media (max-width: 1023px) {
    .main{
        padding-bottom: 360px;
    }    
    .to-trips-summary{
        display: none !important;
    }
    .panel-thumb-sitemap{
        width: 147px;
    }

}

@media (max-width: 767px) {
    .panel-list-bottom > li{
        padding: 0 10px;
    }
    .panel-list-sitemap{
        display: none;
    }
    .panel-list-navigation{
        padding-top: 20px !important;
    }
    .personal-info table td.col1 label{
        padding: 0px;
    }
    .tilOversigt{
        margin-top: 15px !important;
    }
    .caledar-prev-next-date {
        display: none;
    }
    .logo a{
        max-width: 200px;
    }
    .vendor-friendly-name{
        display: none !important;
    }
    .coupon-entry-date-time,
	.coupon-entry-route,
    .coupon-entry-booking,
    .coupon-entry-qty,
    .coupon-entry-ticket,
    .coupon-entry-note,
    .coupon-entry-amount {
		display: block;
        padding: 8px 20px;
        width: 100%;
	}
	.coupon-entry-date-time {
		width: auto;
		padding-top: 20px;
	}
	.coupon-entry-amount {
		text-align: left;
		padding-bottom: 20px;
    }
    .contact-zip-city-label{
        display: none !important;
    }
}

@media (max-width: 479px) {
}

/* TEMPORARY*/
.panel {
    bottom: 0px;
}


.cont-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	display: -webkit-flex; /*IOS line wrap*/
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; /*IOS line wrap*/
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: -11px;
	margin-right: -11px;
	padding-bottom: 20px;
}

.cont-box {
    padding: 21px 11px 0px;
	width: 25%;
}
.cont-box-single{
    min-width: 500px;
}
.order .payment-later {
    margin-bottom: 10px;
}
.remember-to-confirm{
    color: #1d8839;
    font-weight: 700;
}
.payment-later .checkbox label{
    font-weight: 600;
}
.only-reservation-info{
    color: orangered;
    font-size: 20;
}
.clip-as-price-0{
    padding-bottom: 20px;;
}
@media (max-width: 1200px) {

}

@media (max-width: 1200px) {
    .cont-box {
        width: 33.33333%;
    }
    .cont-box-timetable{
        width: 45% !important;
    }
}

@media (max-width: 900px) {
    .cont-box {
        width: 50%;
    }
    .cont-box-timetable{
        width: 50% !important;
    }
    .content-time-title{
        font-size: 12px;
    }
    .content-time-meta {
        padding-top: 12px;
        font-size: 12px;
    }
    .logo-team{
        display: none !important;
    }
}
@media (max-width: 767px) {
    .cont-box-timetable{
        width: 80% !important;
    }
}
@media (max-width: 600px) {
    .cont-box {
        width: 80%;
    }
    .cont-box-timetable{
        width: 100% !important;
    }
    .cont-box-single{
        min-width: 225px;
    }
    
    .flag-current img {
        height: 25px;
    }
}

